<template>
  <div class="page-content">
    <sidebar-menu />
    <router-view />
    <sidebar-filter class="d-none" />
  </div>
</template>

<script>
import SidebarMenu from '../components/common/SidebarMenu.vue'
import SidebarFilter from '../components/common/SidebarFilter.vue'
export default {
  components: { SidebarMenu, SidebarFilter },
  created () {
    const routes = [this.$store.state.prevPg, this.$route.name]
    if (routes.includes('einkauf') && routes.includes('verkauf')) {
      this.$store.commit('SET_CURRENT_PAGE_STATE', { type: 'supplier', pos: 0 })
    }
  }
}
</script>
