<template>
  <div class="page-content">
      <sidebar-menu />
      <router-view @loading="changeLoading" />
  </div>
</template>

<script>
import SidebarMenu from '../components/common/SidebarMenu.vue'

export default {
  components: { SidebarMenu },
  data () {
    return {
      loading: true
    }
  },
  methods: {
    changeLoading (par) {
      this.loading = par
    }
  }
}
</script>
